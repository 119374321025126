/* eslint-disable class-methods-use-this */
import { observable, makeObservable, action } from 'mobx';
import { setServer } from '../service/jiraOPService';
import config from '../config';
import Request from '../service/request';

const REST_URL = config.jiraAPI;

const getAP = () => {
  if (!window.AP || !window.AP.context || !window.AP.user) setServer();
  return window.AP;
};

class JiraStore {
  constructor() {
    if (window?.AP?.theming) window.AP.theming.initializeTheming();
    const schemaStr = (window.testomatioConfig && window.testomatioConfig.backendUrlSchema && (window.testomatioConfig.backendUrlSchema === 'http://')) ? 'http://' : 'https://';
    this.testomatioURL = (window.testomatioConfig && window.testomatioConfig.backendUrl) ? (schemaStr + window.testomatioConfig.backendUrl) : undefined;

    this.toast = null;
    this.branch = null;
    this.branches = [];
    this.issue = null;
    this.issueName = '';
    this.issues = null;
    this.suites = null;
    this.jiraId = null;
    this.projects = null;
    this.currentUser = null;
    this.childIssues = [];
    this.currentIssue = null;
    this.subscription = true;
    this.currentProject = null;
    this.associatedItems = null;
    this.associatedTests = null;
    this.associatedSuites = null;
    this.associatedRuns = null;
    this.associatedPlans = null;
    this.testomatioProject = null;
    this.testomatioToken = null;
    this.isLoading = false;
    this.runDetails = null;
    this.showAllProjects = false;
    this.associatedProject = false;

    makeObservable(this, {
      // state
      toast: observable,
      branch: observable,
      branches: observable,
      issue: observable,
      issueName: observable,
      issues: observable,
      suites: observable,
      jiraId: observable,
      projects: observable,
      childIssues: observable,
      currentUser: observable,
      currentIssue: observable,
      // testomatioURL: observable,
      currentProject: observable,
      associatedTests: observable,
      subscription: observable,
      testomatioToken: observable,
      associatedSuites: observable,
      testomatioProject: observable,
      showAllProjects: observable,

      // actions
      getIssue: action,
      setCurrentIssue: action,
      setBranch: action,
      setJiraId: action,
      getJiraId: action,
      getAllIssues: action,
      getTestDetail: action,
      getIssueTypes: action,
      getCurrentUser: action,
      getSuiteDetail: action,
      getChildIssues: action,
      getCurrentIssue: action,
      getCurrentProject: action,
      getAssociatedTests: action,
      getIssuesWithTests: action,
      getTestomatioToken: action,
      getAssociatedSuites: action,
      getTestomatioProject: action,
      getTestDetailForIssue: action,
      getNavigateToIssuePage: action,
      getSuiteDetailForIssue: action,
      addBranch: action,
      getIssuesBySprint: action,
      getSprint: action,
      setAssociatedProject: action,
    });
  }

  async testomatioRequest(url, options = {}, prefix = this.apiPrefix) {
    if (this.user && !this.user.isLoggedIn) return;

    const request = new Request(this.testomatioToken);
    if (this.branch) request.setBranch(this.branch);
    try {
      const resp = await request.send(prefix + url, options);
      if (options.success) {
        this.toast = {
          type: 'success',
          message: options.success,
        };
      }
      return resp;
    } catch (err) {
      this.toast = {
        type: 'error',
        message: err.message,
      };
      // throw err;
    }
  }

  get apiPrefix() {
    return `/api/${this.testomatioProject.slug}`;
  }

  get projectUrl() {
    return `${this.testomatioURL}/projects/${this.testomatioProject.slug}`;
  }

  get isGherkin() {
    return this.testomatioProject?.lang === 'gherkin';
  }

  get branchId() {
    return (this.branch && this.branch.id) ? this.branch.id : config.mainBranchSlug;
  }

  get theme() {
    const htmlElement = document.documentElement;
    return htmlElement?.getAttribute('data-color-mode') || 'light';
  }

  async setCurrentIssue(issueId) {
    this.currentIssue = issueId;
    if (issueId) {
      const issue = await this.getIssue(issueId);
      this.issueName = issue.fields.summary;
      this.jiraId = issue.key;
    } else {
      this.jiraId = null;
    }
  }

  async setBranch(branch) {
    if (!branch) {
      this.branch = null;
      return;
    }
    const resp = await this.testomatioRequest(`/branches/${branch.id}`, {
      method: 'GET',
    });
    if (!resp) {
      this.toast = { type: 'error', message: 'Branch was not found' };
      return;
    }
    this.branch = branch;
    this.addBranch(branch);
  }

  getCurrentIssue() {
    this.isLoading = true;
    return new Promise((res, rej) => {
      getAP().context.getContext((response) => {
        this.isLoading = false;
        if (response && response.jira && response.jira.issue) {
          this.currentIssue = response.jira.issue.id;
          this.jiraId = response.jira.issue.key;
          res(this.currentIssue);
        } else {
          this.toast = {
            type: 'error',
            message: 'Not in the issue page',
          };
          rej();
        }
      });
    });
  }

  getIssue(key) {
    return new Promise((res) => {
      getAP().request(`${REST_URL}/issue/${key}`, {
        success: async (responseText) => {
          const response = JSON.parse(responseText);
          if (response.id) {
            res(response);
          } else {
            this.toast = {
              type: 'error',
              message: 'No issue found',
            };
            res({});
          }
        },
        error: () => res({}),
      });
    });
  }

  getChildIssues(key) {
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/search?jql="epic link"=${key}&maxResults=1000`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.issues && response.issues.length > 0) {
              this.childIssues = response.issues;
              res(this.childIssues);
            } else if (response.issues.length === 0) {
              getAP().request(
                `${REST_URL}/search?jql=parent=${key}&maxResults=1000`,
                {
                  success: (resp) => {
                    const r = JSON.parse(resp);
                    if (r.issues) res(r.issues);
                    res([]);
                  },
                  error: () => {
                    res([]);
                  },
                },
              );
            } else {
              res([]);
            }
          },
          error: () => {
            res([]);
          },
        },
      );
    });
  }

  getAssociatedTests(issueId, slug = this.testomatioProject.slug) {
    return new Promise((res) => {
      getAP().request(`${REST_URL}/issue/${issueId}/properties`, {
        success: (responseText) => {
          const response = JSON.parse(responseText);
          this.associatedItems = response;
          const resultItems = this.getItemsListFromJiraPropertiesResponse('tests', slug, response);
          res(resultItems);
        },
        error: () => {
          res([]);
        },
      });
    });
  }

  setAssociatedProject() {
    if (this.associatedProject || !this.projects || this.projects?.length === 1) return;
    this.associatedProject = true;
    const byProject = {};
    this.associatedItems.keys.filter(x => x.key).forEach(k => {
      this.projects.forEach(p => {
        const keyPrefix = `${p.slug}.testomatio.tests.`;
        if (k.key.startsWith(keyPrefix)) {
          byProject[p.slug] = p;
        }
      });
    });
    if (Object.keys(byProject).length === 1) {
      const [project] = Object.values(byProject);
      this.setTestomatioProject(project);
    }
  }

  // Loading array of issue items from issue properties list
  // In properties list item key stored as /issue/${issueId}/properties/${projectSlug}.testomatio.tests.#{test_uid}.#{branch_slug}
  // So we filter properties by key_prefix, and choose linked items only for chosen branch_slug
  getItemsListFromJiraPropertiesResponse(model_name, projectSlug, response) {
    const result = [];
    const branchSlug = (this.branch) ? this.branch.id : config.mainBranchSlug;
    if (!(response.keys)) return result;
    const keyPrefix = `${projectSlug}.testomatio.${model_name}.`;
    response.keys.filter(x => x.key).forEach(prop => {
      if (prop.key.startsWith(keyPrefix)) {
        const itemKey = prop.key.slice(keyPrefix.length);
        const keyArr = itemKey.split('.').filter(x => x.length);
        const keyBranch = (keyArr.length > 1) ? keyArr[1] : config.mainBranchSlug;
        const itemUid = keyArr[0];
        if ((itemUid.length > 0) && ((keyBranch === config.mainBranchSlug) || (keyBranch === branchSlug))) {
          // Here we looking for best offer - if we have record in the branch_slug - we must take this record
          // If we do not found property with branch_slug - we need take record from Main branch
          // START [SearchBestBranchOffer]
          let alreadyFound = false;
          result.forEach(item => {
            if (item.id === itemUid) {
              alreadyFound = true;
              if (keyBranch === branchSlug) {
                item.branch = keyBranch;
                item.property_url = prop.self;
              }
            }
          });
          if (!(alreadyFound)) {
            result.push({
              id: itemUid, branch: keyBranch, branchSlug, property_url: prop.self,
            });
          }
          // END [SearchBestBranchOffer]
        }
      }
    });
    return result;
  }

  async getAssociatedSuites(issueId = this.currentIssue, slug = this.testomatioProject.slug) {
    if (!issueId) return Promise.resolve(null);
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issueId}/properties`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            const resultItems = this.getItemsListFromJiraPropertiesResponse('suites', slug, response);
            this.associatedSuites = resultItems;
            res(resultItems);
          },
          error: () => {
            this.associatedSuites = [];
            res([]);
          },
        },
      );
    });
  }

  getCurrentProject() {
    return new Promise((res) => {
      getAP().context.getContext((response) => {
        if (response && response.jira && response.jira.project) {
          console.log(config.appPrefix, "Loaded project context:", response);
          let { project } = response.jira;
          if (!response.jira.project.key) {
            // in jira server project may not be returned when issue is opened from Search screen
            const key = response.jira.issue.key.split('-')[0];
            project = { key };
          }
          this.currentProject = project;
          res(project);
        } else {
          this.toast = {
            type: 'error',
            message: 'Not in the issue page',
          };
        }
        res(null);
      });
    });
  }

  async getTestomatioProjects() {
    if (!this.currentProject) return;
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/project/${this.currentProject?.key}/properties/testomatio.projects`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              this.projects = response.value;
            } else {
              this.toast = {
                type: 'error',
                message: 'No projects available',
              };
            }
            res(null);
          },
          error: (err) => {
            this.toast = {
              type: 'error',
              message: 'No projects available',
            };
            console.error(err); // eslint-disable-line
            res(null);
          },
        },
      );
    });
  }

  async getTestomatioProject() {
    if (!this.projects) {
      await this.getTestomatioProjects();
    }
    if (!this.currentProject || !this.projects) return;

    const project = this.projects[0];
    if (!project?.slug) return;

    const projectSlug = localStorage.getItem(`${this.currentProject.key}.project`);
    if (projectSlug) {
      const proj = this.projects.filter(p => p.slug === projectSlug)[0];
      if (proj) {
        this.testomatioProject = proj;
        return proj;
      }
    }
    this.setTestomatioProject(project);
    return this.testomatioProject;
  }

  setTestomatioProject(project) {
    localStorage.setItem(`${this.currentProject.key}.project`, project.slug);
    this.testomatioProject = project;
  }

  async checkProjectAccess() {
    await this.getCurrentUser();
    if (!this.currentUser) return false;
    const request = new Request(this.testomatioToken);
    try {
      const res = await request.send(`/api/projects/${this.testomatioProject.slug}`, { method: 'GET' });
      this.subscription = res.data.attributes.subscription;
      return true;
    } catch (e) {
      return false;
    }
  }

  addBranch(branch) {
    if (!this.branches.map(b => b.id).filter(bs => bs === branch.id).length) {
      this.branches.push(branch);
    }
  }

  async getTestDetail(testItem, issueId = null) {
    const issue = issueId || this.currentIssue;
    const branchSlug = testItem.branch_slug || config.mainBranchSlug;
    let currentBranchPropUrl = testItem.property_url;
    let mainBranchPropUrl = '';
    if (!(currentBranchPropUrl)) {
      currentBranchPropUrl = `${REST_URL}/issue/${issue}/properties/${this.testomatioProject.slug}.testomatio.tests.${testItem.id}`;
      if (!(branchSlug === config.mainBranchSlug)) {
        mainBranchPropUrl = currentBranchPropUrl;
        currentBranchPropUrl = `${mainBranchPropUrl}.${branchSlug}`;
      }
    }
    return new Promise((res) => {
      getAP().request(currentBranchPropUrl, {
        success: (currentBranchResponseText) => {
          const currentBranchResponse = JSON.parse(currentBranchResponseText);
          if (currentBranchResponse.value) {
            currentBranchResponse.value.id = testItem.id;
            currentBranchResponse.value.branch_slug = branchSlug;
            if (currentBranchResponse.value.branch) this.addBranch(currentBranchResponse.value.branch);
            res(currentBranchResponse.value);
          } else {
            if ((mainBranchPropUrl.length > 0) && (currentBranchResponse.errorMessages) && (currentBranchResponse.errorMessages[0].match(/^The property .* does not exist/))) {
              getAP().request(mainBranchPropUrl, {
                success: (mainBranchResponseText) => {
                  const mainBranchResponse = JSON.parse(mainBranchResponseText);
                  if (mainBranchResponse.value) {
                    mainBranchResponse.value.id = testItem.id;
                    mainBranchResponse.value.branch_slug = branchSlug;
                    if (mainBranchResponse.value.branch) this.addBranch(mainBranchResponse.value.branch);
                    res(mainBranchResponse.value);
                  } else {
                    res({ id: testItem.id, branch_slug: branchSlug });
                  }
                },
                error: () => {
                  res({ id: testItem.id, branch_slug: branchSlug });
                },
              });
            }
            res({ id: testItem.id });
          }
        },
        error: () => {
          if (mainBranchPropUrl.length > 0) {
            getAP().request(mainBranchPropUrl, {
              success: (mainBranchResponseText2) => {
                const mainBranchResponse2 = JSON.parse(mainBranchResponseText2);
                if (mainBranchResponse2.value) {
                  mainBranchResponse2.value.id = testItem.id;
                  mainBranchResponse2.value.branch_slug = branchSlug;
                  if (mainBranchResponse2.value.branch) this.addBranch(mainBranchResponse2.value.branch);
                  res(mainBranchResponse2.value);
                } else res({ id: testItem.id, branch_slug: branchSlug });
              },
              error: () => {
                res({ id: testItem.id, branch_slug: branchSlug });
              },
            });
          } else {
            res({ id: testItem.id, branch_slug: branchSlug });
          }
        },
      });
    });
  }

  async getSuiteDetail(suiteItem, issueId = null) {
    const issue = issueId || this.currentIssue;
    const branchSlug = suiteItem.branch_slug || config.mainBranchSlug;
    let currentBranchPropUrl = suiteItem.property_url;
    let mainBranchPropUrl = '';
    if (!(currentBranchPropUrl)) {
      currentBranchPropUrl = `${REST_URL}/issue/${issue}/properties/${this.testomatioProject.slug}.testomatio.suites.${suiteItem.id}`;
      if (!(branchSlug === config.mainBranchSlug)) {
        mainBranchPropUrl = currentBranchPropUrl;
        currentBranchPropUrl = `${mainBranchPropUrl}.${branchSlug}`;
      }
    }
    return new Promise((res) => {
      getAP().request(currentBranchPropUrl, {
        success: (currentBranchResponseText) => {
          const currentBranchResponse = JSON.parse(currentBranchResponseText);
          if (currentBranchResponse.value) {
            currentBranchResponse.value.id = suiteItem.id;
            currentBranchResponse.value.branch_slug = branchSlug;
            if (currentBranchResponse.value.branch) this.addBranch(currentBranchResponse.value.branch);
            res(currentBranchResponse.value);
          } else {
            if ((mainBranchPropUrl.length > 0) && (currentBranchResponse.errorMessages) && (currentBranchResponse.errorMessages[0].match(/^The property .* does not exist/))) {
              getAP().request(mainBranchPropUrl, {
                success: (mainBranchResponseText) => {
                  const mainBranchResponse = JSON.parse(mainBranchResponseText);
                  if (mainBranchResponse.value) {
                    mainBranchResponse.value.id = suiteItem.id;
                    mainBranchResponse.value.branch_slug = branchSlug;
                    if (mainBranchResponse.value.branch) this.addBranch(mainBranchResponse.value.branch);
                    res(mainBranchResponse.value);
                  } else {
                    res({ id: suiteItem.id, branch_slug: branchSlug });
                  }
                },
                error: () => {
                  res({ id: suiteItem.id, branch_slug: branchSlug });
                },
              });
            }
            res({ id: suiteItem.id });
          }
        },
        error: () => {
          if (mainBranchPropUrl.length > 0) {
            getAP().request(mainBranchPropUrl, {
              success: (mainBranchResponseText2) => {
                const mainBranchResponse2 = JSON.parse(mainBranchResponseText2);
                if (mainBranchResponse2.value) {
                  mainBranchResponse2.value.id = suiteItem.id;
                  mainBranchResponse2.value.branch_slug = branchSlug;
                  if (mainBranchResponse2.value.branch) this.addBranch(mainBranchResponse2.value.branch);
                  res(mainBranchResponse2.value);
                } else res({ id: suiteItem.id, branch_slug: branchSlug });
              },
              error: () => {
                res({ id: suiteItem.id, branch_slug: branchSlug });
              },
            });
          } else {
            res({ id: suiteItem.id, branch_slug: branchSlug });
          }
        },
      });
    });
  }

  async getTestDetailForIssue(issueId, slug = this.testomatioProject.slug) {
    const tests = await this.getAssociatedTests(issueId, slug);
    if (tests) {
      const testDetailsPromise = tests.map((testItem) => this.getTestDetail(testItem, issueId));
      return (await Promise.allSettled(testDetailsPromise)).map((p) => {
        if (!p.value && p.id) {
          return {
            id: p.id,
            title: 'Synchronizing... Reload this tab',
            type: 'test',
            status: [],
          };
        }
        if (!p.value && !p.id) {
          return {
            id: '',
            title: 'Not loaded.',
            type: 'test',
            status: [],
          };
        }
        return p.value;
      });
    }
    return [];
  }

  async getSuiteDetailForIssue(issueId) {
    const suites = await this.getAssociatedSuites(issueId);
    if (suites) {
      const detailsPromise = suites.map((suitePropInfo) => this.getSuiteDetail(suitePropInfo, issueId));
      return (await Promise.allSettled(detailsPromise)).map((p) => {
        if (!p.value && p.id) {
          return {
            id: p.id,
            title: 'Synchronizing... Reload this tab',
            type: 'suite',
            status: [],
          };
        }
        if (!p.value && !p.id) {
          return {
            id: '',
            title: 'Not loaded.',
            type: 'suite',
            status: [],
          };
        }
        return p.value;
      });
    }
    return [];
  }

  async getAllIssues(projectId, query = null) {
    const jqlQuery = (query && query.length > 0) ? query : `project=${projectId}&maxResults=1000`;
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/search?jql=${jqlQuery}`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.issues) {
              res(response.issues);
            }
            res([]);
          },
          error: () => {
            this.toast = {
              type: 'error',
              message: 'No results matches criteria',
            };
            res([]);
          },
        },
      );
    });
  }

  async getIssuesBySprint(sprintId) {
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/search?jql=sprint=${sprintId}&maxResults=1000`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response) {
              res(response.issues);
            } else {
              res({});
            }
          },
          error: () => {
            res(null);
          },
        },
      );
    });
  }

  async getIssuesWithTests(projectId, query = null) {
    this.issues = await this.getAllIssues(projectId, query);
    let issuesArray = [];
    const pros = (this.showAllProjects ? this.projects : [this.testomatioProject]).filter(n => n);
    // eslint-disable-next-line
    for (const issue of this.issues) {
      let issueTests = [];
      let issueProjectsCount = 0;
      // eslint-disable-next-line
      for (const p of pros) {
        // eslint-disable-next-line
        const tests = await this.getTestDetailForIssue(issue.id, p.slug);
        // eslint-disable-next-line
        if (tests.length > 0) issueProjectsCount++;
        issueTests = [...issueTests, ...tests];
      }
      issuesArray = [...issuesArray, { ...issue, tests: issueTests, issueProjectsCount }];
    }
    return Promise.all(issuesArray.sort((a, b) => {
      if (a.tests.length < b.tests.length) {
        return 1;
      }
      if (a.tests.length > b.tests.length) {
        return -1;
      }
      return 0;
    }));
  }

  async getIssueTypes(projectId) {
    return new Promise((res) => {
      getAP().request(`${REST_URL}/project/${projectId}`, {
        success: (responseText) => {
          const resp = JSON.parse(responseText);
          if (resp.issueTypes) {
            res(resp.issueTypes);
          }
        },
      });
    });
  }

  getNavigateToIssuePage(issueId) {
    getAP().navigator.go('site', {
      relativeUrl: `/browse/${issueId}`,
    });
  }

  getCurrentUser() {
    return new Promise((res) => {
      getAP().user.getCurrentUser((user) => {
        if (user) {
          this.currentUser = user;
        }
        res(null);
      });
    });
  }

  async getTestomatioToken() {
    await this.getCurrentUser();
    console.log(config.appPrefix, 'Loading auth token from cookie', { account: this.currentUser.atlassianAccountId, name: this.currentUser.username, url: this.testomatioURL });
    if (!this.currentUser) return null;

    const cookieToken = this.getPluginCookie(this.testomatCookieName(this.testomatioURL, 'jwt'));
    console.log(config.appPrefix, 'Loaded auth token from cookie:', cookieToken);
    if (cookieToken.length > 0) {
      try {
        const request = new Request(cookieToken);
        const data = await request.send('/api/verify', { method: 'GET' });
        if (data.message === 'ok') {
          console.log(config.appPrefix, 'Auth token from cookie has been verified');
          this.testomatioToken = cookieToken;
          return cookieToken;
        }
      } catch (e) {
        console.error(config.appPrefix, 'Failed to verify cookie auth token', e); // eslint-disable-line
      }
    }

    console.log(config.appPrefix, 'Loading auth token from property', { account: this.currentUser.atlassianAccountId, username: this.currentUser.username });
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/user/properties/testomatio.token?accountId=${this.currentUser.atlassianAccountId}&username=${this.currentUser.username}`,
        {
          success: (responseText) => {
            console.log(config.appPrefix, 'Loading auth token from property response:', responseText);
            const response = JSON.parse(responseText);
            if (response.value && response.value.token) {
              this.testomatioToken = response.value.token;
              console.log(config.appPrefix, 'Auth token loaded from property success. Token:', this.testomatioToken);
            }
            res(this.testomatioToken);
          },
          error: () => {
            console.log(config.appPrefix, 'Loading auth token from property failed.');
            this.testomatioToken = null;
            res(null);
          },
        },
      );
    });
  }

  testomatCookieName(testomatDomain, name) {
    const account_id = this.getJiraAccountIdent();
    const result = `${account_id}_${testomatDomain}`.replaceAll(/[^A-Za-z0-9_]/ig, '_');
    return `testomat_${name}_${result}`;
  }

  getJiraAccountIdent() {
    const jiraUser = this.currentUser;
    if (!jiraUser) return null;
    const account_id = jiraUser.atlassianAccountId || jiraUser.username || 'noUser';
    return `${account_id}`;
  }

  setPluginCookie(cookieName, value) {
    const domainUri = new URL('/', window.location.href);
    const secure = (domainUri.protocol === 'https:') ? 'Secure;' : '';
    const domain = domainUri.host.split(':')[0];
    const sameSite = (secure) ? 'None' : 'Lax';
    const set_cookie_str = `${cookieName}=${value};Max-Age=257680000;domain=${domain};SameSite=${sameSite};${secure}`;
    document.cookie = `${set_cookie_str}path=/;`;
  }

  getPluginCookie(cookieName) {
    let result = '';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${cookieName}=`)) result = cookie.substring(cookieName.length + 1);
    }
    return result;
  }

  setJiraId(jiraId) {
    this.jiraId = jiraId;
  }

  getJiraId() {
    return this.jiraId;
  }

  getAssociatedPlans(issueId, slug = this.testomatioProject.slug) {
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issueId}/properties/${slug}.testomatio.plans`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              if (issueId === this.currentIssue) {
                this.associatedPlans = response.value;
              }
              return res(response.value);
            }
            res([]);
          },
          error: () => {
            res([]);
          },
        },
      );
    });
  }

  getAssociatedRuns(issueId, slug = this.testomatioProject.slug) {
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issueId}/properties/${slug}.testomatio.runs`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              if (issueId === this.currentIssue) {
                this.associatedRuns = response.value;
              }
              return res(response.value);
            }
            res([]);
          },
          error: () => {
            res([]);
          },
        },
      );
    });
  }

  async getRunDetails(runID, issueId = null, slug = this.testomatioProject.slug) {
    const issue = issueId || this.currentIssue;
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issue}/properties/${slug}.testomatio.runs.${runID}`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              response.value.id = runID;
              if (response.value.branch) this.addBranch(response.value.branch);
              res(response.value);
            } else res({ id: runID });
          },
          error: () => {
            res({ id: runID });
          },
        },
      );
    });
  }

  getTestsBySlug(issueId, slug) {
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issueId}/properties/${slug}.testomatio.tests`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              if (issueId === this.currentIssue) {
                this.associatedTests = response.value;
              }
              return res(response.value);
            }
            res([]);
          },
          error: () => {
            res([]);
          },
        },
      );
    });
  }

  getTestDetailsBySlug(testId, issueId = null, slug) {
    const issue = issueId || this.currentIssue;
    return new Promise((res) => {
      getAP().request(
        `${REST_URL}/issue/${issue}/properties/${slug}.testomatio.tests.${testId}`,
        {
          success: (responseText) => {
            const response = JSON.parse(responseText);
            if (response.value) {
              response.value.id = testId;
              if (response.value.branch) this.addBranch(response.value.branch);
              res(response.value);
            } else res({ id: testId });
          },
          error: () => {
            res({ id: testId });
          },
        },
      );
    });
  }

  async getAllTestsDetails(issueId, slug) {
    const tests = await this.getTestsBySlug(issueId, slug);
    if (tests) {
      const testDetailsPromise = tests.map((testId) => this.getTestDetailsBySlug(testId, issueId, slug));
      return (await Promise.allSettled(testDetailsPromise)).map((p) => {
        if (!p.value && p.id) {
          return {
            id: p.id,
            title: 'Synchronizing... Reload this tab',
            type: 'test',
            status: [],
          };
        }
        if (!p.value && !p.id) {
          return {
            id: '',
            title: 'Not loaded.',
            type: 'test',
            status: [],
          };
        }
        return p.value;
      });
    }
    return [];
  }

  getSprint(sprintId) {
    return new Promise((res) => {
      getAP().request(`/rest/agile/1.0/sprint/${sprintId}`, {
        success: async (responseText) => {
          const response = JSON.parse(responseText);
          if (response.id) {
            res(response);
          } else {
            this.toast = {
              type: 'error',
              message: 'No sprint found',
            };
            res({});
          }
        },
        error: () => res({}),
      });
    });
  }

  clearSessionSettings() {
    localStorage.setItem('page', 1);
  }
}

export default JiraStore;
